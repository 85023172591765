import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

const HelpIcon = (props) => {
  const theme = useTheme();
  const color = props.color ? props.color : theme.palette.light.main;

  return (
    <SvgIcon {...props}>
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM19.46 9.12L16.68 10.27C16.4295 9.59612 16.0362 8.98443 15.527 8.47694C15.0177 7.96945 14.4047 7.57818 13.73 7.33L14.88 4.55C16.98 5.35 18.65 7.02 19.46 9.12ZM12 15C10.34 15 9 13.66 9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15ZM9.13 4.54L10.3 7.32C9.61732 7.56879 8.99706 7.96338 8.48241 8.4763C7.96776 8.98923 7.57108 9.60815 7.32 10.29L4.54 9.13C4.94099 8.08615 5.55677 7.13817 6.34747 6.34747C7.13817 5.55677 8.08615 4.94099 9.13 4.54ZM4.54 14.87L7.32 13.72C7.56934 14.4001 7.96436 15.0174 8.47741 15.5287C8.99045 16.04 9.6091 16.433 10.29 16.68L9.12 19.46C8.07838 19.0572 7.13269 18.4406 6.34385 17.6501C5.55502 16.8595 4.94054 15.9125 4.54 14.87ZM14.88 19.46L13.73 16.68C14.4079 16.4288 15.0229 16.0331 15.5324 15.5202C16.0418 15.0073 16.4334 14.3896 16.68 13.71L19.46 14.88C19.0577 15.9203 18.4425 16.8651 17.6538 17.6538C16.8651 18.4425 15.9203 19.0577 14.88 19.46Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default HelpIcon;
