import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

const ManageIcon = (props) => {
  const theme = useTheme();
  const color = props.color ? props.color : theme.palette.light.main;

  return (
    <SvgIcon {...props}>
      <path
        d="M15.6364 11.5714C17.1455 11.5714 18.3545 10.3271 18.3545 8.78571C18.3545 7.24429 17.1455 6 15.6364 6C14.1273 6 12.9091 7.24429 12.9091 8.78571C12.9091 10.3271 14.1273 11.5714 15.6364 11.5714ZM8.36364 11.5714C9.87273 11.5714 11.0818 10.3271 11.0818 8.78571C11.0818 7.24429 9.87273 6 8.36364 6C6.85455 6 5.63636 7.24429 5.63636 8.78571C5.63636 10.3271 6.85455 11.5714 8.36364 11.5714ZM8.36364 13.4286C6.24545 13.4286 2 14.515 2 16.6786V19H14.7273V16.6786C14.7273 14.515 10.4818 13.4286 8.36364 13.4286ZM15.6364 13.4286C15.3727 13.4286 15.0727 13.4471 14.7545 13.475C15.8091 14.255 16.5455 15.3043 16.5455 16.6786V19H22V16.6786C22 14.515 17.7545 13.4286 15.6364 13.4286Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default ManageIcon;
