import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  filterBox: {
    width: '250px',
    backgroundColor: '#FFF',
    position: 'absolute',
    top: 28,
    right: 0,
    left: 0,
    zIndex: 1,
    borderRadius: '12px',
    boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
  },
  filterTextField: {
    display: 'flex',
    width: '100%',
    maxWidth: '250px',
    padding: '10px',
  },
  requestStatusFilterBox: {
    display: 'flex',
    width: '220px',
    backgroundColor: '#FFF',
    position: 'absolute',
    top: 28,
    right: 0,
    left: ({ statusType }) => (statusType === 'history' ? 'auto' : 0),
    zIndex: 1,
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: ' 0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
  },
  requestFilterBox: {
    display: 'flex',
    minWidth: '250px',
    maxHeight: '300px',
    backgroundColor: '#FFF',
    position: 'absolute',
    top: 28,
    right: 0,
    left: ({ statusType }) => (statusType === 'history' ? 'auto' : 0),
    zIndex: 1,
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
    borderRadius: '12px',
    background: '#FFF',
    boxShadow: ' 0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  formGroupStyle: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    width: '100%',
    marginBottom: '2px',
  },
  formControllLabelStyle: {
    '&.MuiFormControlLabel-root': {
      color: '#848484',
      fontFamily: 'Greycliff, Helvetica, sans-serif',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '18px',
      display: 'flex',
      alignItems: 'start',
      whiteSpace: 'normal',
    },
  },
  filterInput: {
    marginRight: '0px !important',
  },
  textField: {
    width: '200px',
    '&.MuiFormControl-root': {
      marginBottom: '0px !important',
    },
  },
  apply: {
    color: '#3F6C7B',
    fontFamily: 'Greycliff, Helvetica, sans-serif',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '18px',
  },
  checkbox: {
    color: '#E0E0E0',
    borderRadius: '4px',
    padding: '0px !important',
    paddingInline: '9px !important',
    '&.Mui-checked': {
      color: '#87C7C9',
    },
  },
}));
