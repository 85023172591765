import React from 'react';

import RequestForm from './RequestForm';

const RequestCreatePage = () => {
  return (
    <RequestForm requestLoading={false} />
  );
};

export default RequestCreatePage;
