import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

const WrenchIcon = (props) => {
  const theme = useTheme();
  const color = props.color ? props.color : theme.palette.light.main;

  return (
    <SvgIcon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5756 9.90948L22.6556 18.9895C23.0556 19.3895 23.0556 20.0195 22.6556 20.3995L20.3556 22.6995C19.9656 23.0895 19.3356 23.0895 18.9456 22.6995L9.83562 13.5895C7.51562 14.4595 4.80562 13.9695 2.94562 12.1095C0.645616 9.81948 0.435616 6.22948 2.29562 3.68948L6.12562 7.51948L7.54562 6.10948L3.70562 2.25948C6.25562 0.399483 9.83562 0.609483 12.1356 2.90948C14.0256 4.80948 14.5056 7.56948 13.5756 9.90948ZM10.1956 11.1295L19.6556 20.5895L20.5356 19.6995L11.0856 10.2495C11.5456 9.65948 11.8456 8.99948 11.9656 8.28948C12.2156 6.89948 11.8056 5.40948 10.7256 4.32948C9.77562 3.38948 8.52562 2.94948 7.28562 3.01948L10.3756 6.10948L6.13562 10.3495L3.04562 7.25948C2.97562 8.49948 3.41562 9.75948 4.36562 10.6995C5.40562 11.7395 6.83562 12.1495 8.19562 11.9495C8.90562 11.8495 9.58562 11.5795 10.1956 11.1295Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default WrenchIcon;
