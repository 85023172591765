export const filterLambda = {
  caseManager: 'care_manager.userprofile.name',
  services: 'services',
  funding: 'funding',
};

export const filterOptions = [
  { key: 'opened', label: 'Open' },
  { key: 'partiallyMatched', label: 'Partially Matched' },
  { key: 'pending', label: 'Received' },
  { key: 'archived', label: 'Archived' },
  { key: 'closed', label: 'Matched' },
];

export const services = [
  'Home Modifications',
  'Homemaker/Home Community Assistance',
  'Integrated Health Care Coordination',
  'Nutritional Supplements',
  'Personal Emergency Response System',
  'Pest Control',
  'Respite Home Health Aide',
  'Respite Nursing LPN',
  'Specialized Medical Equipment and Supplies',
  'Structured Family Caregiving',
  'Transportation',
  'Vehicle Modifications',
  'Respite Nursing RN',
  'Respite Nursing RN/LPN',
  'Skilled Nursing RN',
  'Skilled Nursing LPN',
  'Skilled Nursing RN/LPN',
  'Home Health Aide',
  'PT/OT',
];
