import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

const AddIcon = (props) => {
  const theme = useTheme();
  const color = props.color ? props.color : theme.palette.light.main;

  return (
    <SvgIcon {...props}>
      <path
        d="M17.125 10.625H13.375V6.875C13.375 6.37772 13.1775 5.90081 12.8258 5.54917C12.4742 5.19754 11.9973 5 11.5 5C11.0027 5 10.5258 5.19754 10.1742 5.54917C9.82254 5.90081 9.625 6.37772 9.625 6.875L9.69156 10.625H5.875C5.37772 10.625 4.90081 10.8225 4.54917 11.1742C4.19754 11.5258 4 12.0027 4 12.5C4 12.9973 4.19754 13.4742 4.54917 13.8258C4.90081 14.1775 5.37772 14.375 5.875 14.375L9.69156 14.3084L9.625 18.125C9.625 18.6223 9.82254 19.0992 10.1742 19.4508C10.5258 19.8025 11.0027 20 11.5 20C11.9973 20 12.4742 19.8025 12.8258 19.4508C13.1775 19.0992 13.375 18.6223 13.375 18.125V14.3084L17.125 14.375C17.6223 14.375 18.0992 14.1775 18.4508 13.8258C18.8025 13.4742 19 12.9973 19 12.5C19 12.0027 18.8025 11.5258 18.4508 11.1742C18.0992 10.8225 17.6223 10.625 17.125 10.625Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default AddIcon;
