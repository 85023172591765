import React from 'react';
import { SvgIcon } from '@material-ui/core';

const ResetIcon = (props) => {
  const color = props.color ? props.color : 'white';

  return (
    <SvgIcon {...props}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.46818 2.2299C5.54106 2.30259 5.59888 2.38895 5.63833 2.48402C5.67778 2.57909 5.69808 2.68101 5.69808 2.78395C5.69808 2.88688 5.67778 2.9888 5.63833 3.08387C5.59888 3.17894 5.54106 3.2653 5.46818 3.33799L3.67302 5.13159H11.9571C13.825 5.13159 15.6164 5.87361 16.9372 7.19442C18.258 8.51522 19 10.3066 19 12.1745C19 14.0424 18.258 15.8338 16.9372 17.1546C15.6164 18.4754 13.825 19.2175 11.9571 19.2175H5.69669C5.48914 19.2175 5.2901 19.135 5.14334 18.9882C4.99659 18.8415 4.91414 18.6424 4.91414 18.4349C4.91414 18.2274 4.99659 18.0283 5.14334 17.8816C5.2901 17.7348 5.48914 17.6524 5.69669 17.6524H11.9571C13.4099 17.6524 14.8032 17.0752 15.8305 16.0479C16.8578 15.0206 17.4349 13.6273 17.4349 12.1745C17.4349 10.7217 16.8578 9.3284 15.8305 8.30111C14.8032 7.27381 13.4099 6.69669 11.9571 6.69669H3.67302L5.46818 8.49028C5.54094 8.56304 5.59866 8.64942 5.63803 8.74448C5.67741 8.83955 5.69768 8.94143 5.69768 9.04433C5.69768 9.14722 5.67741 9.24911 5.63803 9.34418C5.59866 9.43924 5.54094 9.52561 5.46818 9.59837C5.39542 9.67113 5.30905 9.72885 5.21398 9.76822C5.11892 9.8076 5.01703 9.82787 4.91414 9.82787C4.81124 9.82787 4.70935 9.8076 4.61429 9.76822C4.51923 9.72885 4.43285 9.67113 4.36009 9.59837L1.2299 6.46818C1.15703 6.39549 1.09921 6.30913 1.05976 6.21406C1.02031 6.11899 1 6.01707 1 5.91414C1 5.81121 1.02031 5.70929 1.05976 5.61421C1.09921 5.51914 1.15703 5.43279 1.2299 5.36009L4.36009 2.2299C4.43279 2.15703 4.51914 2.09921 4.61421 2.05976C4.70929 2.02031 4.81121 2 4.91414 2C5.01707 2 5.11899 2.02031 5.21406 2.05976C5.30914 2.09921 5.39549 2.15703 5.46818 2.2299Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default ResetIcon;
