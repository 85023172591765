import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

const ProfileIcon = (props) => {
  const theme = useTheme();
  const color = props.color ? props.color : theme.palette.light.main;

  return (
    <SvgIcon {...props}>
      <path
        d="M20 2H4C2.9 2 2 2.88594 2 3.96875V21.0312C2 22.1141 2.9 23 4 23H20C21.1 23 22 22.1141 22 21.0312V3.96875C22 2.88594 21.1 2 20 2ZM19.3333 20.375H4.66667V4.625H19.3333V20.375ZM7.33333 13.8125H16.6667V15.125H7.33333V13.8125ZM7.33333 16.4375H16.6667V17.75H7.33333V16.4375ZM8.66667 7.90625C8.66675 7.64762 8.71859 7.39155 8.81921 7.15264C8.91984 6.91373 9.06728 6.69668 9.25312 6.51386C9.43896 6.33105 9.65956 6.18605 9.90233 6.08716C10.1451 5.98827 10.4053 5.93741 10.668 5.9375C10.9307 5.93759 11.1909 5.98861 11.4336 6.08766C11.6763 6.18671 11.8968 6.33185 12.0825 6.51479C12.2682 6.69773 12.4155 6.91488 12.516 7.15385C12.6164 7.39283 12.6681 7.64894 12.668 7.90756C12.6678 8.42988 12.4569 8.93074 12.0815 9.29995C11.7062 9.66916 11.1973 9.87649 10.6667 9.87631C10.1361 9.87614 9.62725 9.66848 9.25218 9.29902C8.8771 8.92956 8.66649 8.42857 8.66667 7.90625ZM12 9.875H9.33333C8.23333 9.875 7.33333 10.4656 7.33333 11.1875V12.5H14V11.1875C14 10.4656 13.1 9.875 12 9.875Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default ProfileIcon;
