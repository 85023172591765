import React, { useState, useRef } from 'react';
import {
  Button,
  FormControl,
  Input,
  Typography,
  CircularProgress,
  FormHelperText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useSignupStore from '../../store/signup';
import api from '../../lib/api';
import logo from '../../assets/duett-logo-updated.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    backgroundColor: 'white',
    padding: theme.spacing(4),
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    textAlign: 'center',
    maxWidth: 400,
    width: '100%',
    minHeight: '650px',
    alignContent: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
  },
  formControl: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },
  inputField: {
    width: '100%',
    marginRight: '0px',
  },
  submitButton: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
  stepIndicator: {
    marginTop: theme.spacing(2),
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  errorText: {
    color: theme.palette.error.main,
    fontSize: 12,
    textAlign: 'left',
    width: '100%',
    marginTop: theme.spacing(0.5),
    marginLeft: 0,
  },
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  logo: {
    height: '60px',
    marginBottom: theme.spacing(2),
  },
}));

const Step1 = ({ handleNextStep }) => {
  const classes = useStyles();
  const {
    profileData,
    setProfileData,
    isProfileSubmitted,
    setProfileSubmitted,
  } = useSignupStore();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const inputRef = useRef(null);

  const formatPhoneForDisplay = (digits) => {
    let formattedPhone = '';
    if (digits.length > 0) {
      formattedPhone += '(' + digits.slice(0, 3);
    }
    if (digits.length >= 3) {
      formattedPhone += ') ' + digits.slice(3, 6);
    }
    if (digits.length >= 6) {
      formattedPhone += '-' + digits.slice(6, 10);
    }
    return formattedPhone;
  };

  const formatPhoneForStorage = (digits) => {
    if (digits.length === 0) return '';
    return `+1 ${digits.slice(0, 3)} ${digits.slice(3)}`;
  };

  const [phone, setPhone] = useState(() => {
    const storedPhone = profileData.phone || '';
    if (storedPhone.startsWith('+1 ')) {
      const digits = storedPhone.replace(/\D/g, '').slice(1);
      return formatPhoneForDisplay(digits);
    }
    return storedPhone;
  });

  const formatAndSetPhone = (digits) => {
    const displayFormat = formatPhoneForDisplay(digits);
    const storageFormat = formatPhoneForStorage(digits);

    setPhone(displayFormat);
    setProfileData({ ...profileData, phone: storageFormat });
  };

  const handlePhoneInputChange = (e) => {
    const input = e.target.value;
    const cursorPosition = e.target.selectionStart;
    const previousFormattedPhone = phone;
    const previousDigits = previousFormattedPhone.replace(/\D/g, '');
    const currentDigits = input.replace(/\D/g, '');

    if (currentDigits.length < previousDigits.length) {
      formatAndSetPhone(currentDigits);

      setTimeout(() => {
        const newCursorPosition = formatPhoneForDisplay(currentDigits).length;
        inputRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      }, 0);
    } else if (currentDigits.length > previousDigits.length) {
      const newDigits = currentDigits.slice(0, 10);
      formatAndSetPhone(newDigits);

      setTimeout(() => {
        const newCursorPosition = formatPhoneForDisplay(newDigits).length;
        inputRef.current.setSelectionRange(
          newCursorPosition,
          newCursorPosition
        );
      }, 0);
    } else {
      setTimeout(() => {
        inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    }
  };

  const validateForm = () => {
    const errors = {};
    const maxLengths = {
      first_name: 30,
      last_name: 30,
      company: 65,
      legalEntity: 65,
    };

    if (!profileData.first_name) {
      errors.first_name = 'First Name is required';
    } else if (profileData.first_name.length > maxLengths.first_name) {
      errors.first_name = `Character Limit Exceeded. Limit: ${maxLengths.first_name}`;
    }

    if (!profileData.last_name) {
      errors.last_name = 'Last Name is required';
    } else if (profileData.last_name.length > maxLengths.last_name) {
      errors.last_name = `Character Limit Exceeded. Limit: ${maxLengths.last_name}`;
    }

    if (!profileData.company) {
      errors.company = 'Company Display Name is required';
    } else if (profileData.company.length > maxLengths.company) {
      errors.company = `Character Limit Exceeded. Limit: ${maxLengths.company}`;
    }

    if (!profileData.legalEntity) {
      errors.legalEntity = 'Legal Entity is required';
    } else if (profileData.legalEntity.length > maxLengths.legalEntity) {
      errors.legalEntity = `Character Limit Exceeded. Limit: ${maxLengths.legalEntity}`;
    }

    const phoneRegex = /^\(\d{3}\) \d{3}-\d{4}$/;
    if (!phoneRegex.test(phone)) {
      errors.phone =
        'Please enter a valid phone number in the format (xxx) xxx-xxxx';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    const payload = {
      account: profileData.company,
      first_name: profileData.first_name,
      last_name: profileData.last_name,
      phone: profileData.phone,
      legal_name: profileData.legalEntity,
    };

    try {
      if (!isProfileSubmitted) {
        await api.post('/api/users/custom-provider-profile/', payload);
        setProfileSubmitted(true);
      } else {
        await api.patch('/api/users/custom-provider-profile/', payload);
      }
      handleNextStep();
    } catch (err) {
      setError('Failed to save your information. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <img src={logo} alt="Duett Logo" className={classes.logo} />
        <Typography variant="h5">Set up your profile</Typography>
        <form onSubmit={handleSubmit} className={classes.form}>
          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.first_name)}
          >
            <Input
              id="first-name-input"
              placeholder="First Name"
              disableUnderline
              fullWidth
              value={profileData.first_name || ''}
              onChange={(e) =>
                setProfileData({ ...profileData, first_name: e.target.value })
              }
              className={classes.inputField}
              inputProps={{ maxLength: 30 }}
            />
            {validationErrors.first_name && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.first_name}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.last_name)}
          >
            <Input
              id="last-name-input"
              placeholder="Last Name"
              disableUnderline
              fullWidth
              value={profileData.last_name || ''}
              onChange={(e) =>
                setProfileData({ ...profileData, last_name: e.target.value })
              }
              className={classes.inputField}
              inputProps={{ maxLength: 30 }}
            />
            {validationErrors.last_name && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.last_name}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.company)}
          >
            <Input
              id="company-name-input"
              placeholder="Company Display Name"
              disableUnderline
              fullWidth
              value={profileData.company || ''}
              onChange={(e) =>
                setProfileData({ ...profileData, company: e.target.value })
              }
              className={classes.inputField}
              inputProps={{ maxLength: 65 }}
            />
            {validationErrors.company && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.company}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.legalEntity)}
          >
            <Input
              id="legal-entity-input"
              placeholder="Legal Entity"
              disableUnderline
              fullWidth
              value={profileData.legalEntity || ''}
              onChange={(e) =>
                setProfileData({ ...profileData, legalEntity: e.target.value })
              }
              className={classes.inputField}
              inputProps={{ maxLength: 65 }}
            />
            {validationErrors.legalEntity && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.legalEntity}
              </FormHelperText>
            )}
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={Boolean(validationErrors.phone)}
          >
            <Input
              id="phone-input"
              placeholder="Phone Number - (xxx) xxx-xxxx"
              disableUnderline
              fullWidth
              value={phone}
              onChange={handlePhoneInputChange}
              inputRef={inputRef}
              className={classes.inputField}
              inputProps={{ maxLength: 14 }}
            />
            {validationErrors.phone && (
              <FormHelperText className={classes.errorText}>
                {validationErrors.phone}
              </FormHelperText>
            )}
          </FormControl>

          {loading ? (
            <div className={classes.loaderContainer}>
              <CircularProgress />
            </div>
          ) : (
            <Button
              type="submit"
              variant="contained"
              className={classes.submitButton}
            >
              Next
            </Button>
          )}
          {error && <Typography color="error">{error}</Typography>}
          <Typography className={classes.stepIndicator}>Step 1 of 5</Typography>
        </form>
      </div>
    </div>
  );
};

export default Step1;
