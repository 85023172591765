import React from 'react';
import { SvgIcon } from '@material-ui/core';

const FilterIcon = (props) => {
  const color = props.color;

  return (
    <SvgIcon {...props}>
      <path
        d="M13.6665 11.9992V17.2518C13.6932 17.4518 13.6265 17.6651 13.4732 17.8051C13.4115 17.8669 13.3383 17.9159 13.2576 17.9493C13.177 17.9828 13.0906 18 13.0033 18C12.916 18 12.8295 17.9828 12.7489 17.9493C12.6682 17.9159 12.595 17.8669 12.5333 17.8051L11.1935 16.4652C11.1208 16.3942 11.0655 16.3073 11.032 16.2113C10.9985 16.1153 10.9876 16.0129 11.0002 15.912V11.9992H10.9802L7.14072 7.07985C7.03247 6.94089 6.98363 6.76473 7.00486 6.58987C7.0261 6.41501 7.11568 6.25566 7.25404 6.14665C7.38069 6.05333 7.52067 6 7.66731 6H16.9994C17.146 6 17.286 6.05333 17.4127 6.14665C17.551 6.25566 17.6406 6.41501 17.6618 6.58987C17.6831 6.76473 17.6342 6.94089 17.526 7.07985L13.6865 11.9992H13.6665Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default FilterIcon;
