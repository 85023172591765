import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

const DescendSortIcon = (props) => {
  const theme = useTheme();
  const color = props.color ? props.color : theme.palette.light.main;

  return (
    <SvgIcon {...props}>
      <circle cx="12" cy="12" r="12" fill="#3F6C7B" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0957 10.038C11.9353 9.87757 11.8451 9.65998 11.8451 9.43309C11.8451 9.2062 11.9353 8.98861 12.0957 8.82815L14.6626 6.26122C14.7415 6.1795 14.8359 6.11431 14.9403 6.06947C15.0447 6.02462 15.157 6.00102 15.2706 6.00003C15.3842 5.99905 15.4969 6.02069 15.602 6.06372C15.7072 6.10674 15.8027 6.17027 15.8831 6.25061C15.9634 6.33095 16.0269 6.42649 16.07 6.53164C16.113 6.6368 16.1346 6.74947 16.1337 6.86308C16.1327 6.97669 16.1091 7.08897 16.0642 7.19336C16.0194 7.29775 15.9542 7.39217 15.8725 7.4711L13.3055 10.038C13.1451 10.1984 12.9275 10.2886 12.7006 10.2886C12.4737 10.2886 12.2561 10.1984 12.0957 10.038Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.4394 10.038C18.279 10.1984 18.0614 10.2885 17.8345 10.2885C17.6076 10.2885 17.39 10.1984 17.2295 10.038L14.6626 7.47107C14.5067 7.30969 14.4205 7.09355 14.4224 6.8692C14.4244 6.64486 14.5144 6.43025 14.673 6.27161C14.8317 6.11297 15.0463 6.02298 15.2706 6.02103C15.495 6.01908 15.7111 6.10532 15.8725 6.26119L18.4394 8.82812C18.5998 8.98857 18.6899 9.20617 18.6899 9.43306C18.6899 9.65994 18.5998 9.87754 18.4394 10.038Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2675 7.72168C15.4945 7.72168 15.7121 7.81183 15.8726 7.97229C16.033 8.13276 16.1232 8.35039 16.1232 8.57732V15.4225C16.1232 15.6494 16.033 15.867 15.8726 16.0275C15.7121 16.188 15.4945 16.2781 15.2675 16.2781C15.0406 16.2781 14.823 16.188 14.6625 16.0275C14.502 15.867 14.4119 15.6494 14.4119 15.4225V8.57732C14.4119 8.35039 14.502 8.13276 14.6625 7.97229C14.823 7.81183 15.0406 7.72168 15.2675 7.72168ZM11.5943 13.9619C11.7547 14.1223 11.8448 14.3399 11.8448 14.5668C11.8448 14.7937 11.7547 15.0113 11.5943 15.1718L9.02733 17.7387C8.86595 17.8946 8.64981 17.9808 8.42546 17.9789C8.20112 17.9769 7.98651 17.8869 7.82787 17.7283C7.66922 17.5696 7.57924 17.355 7.57729 17.1307C7.57534 16.9063 7.66158 16.6902 7.81744 16.5288L10.3844 13.9619C10.5448 13.8015 10.7624 13.7114 10.9893 13.7114C11.2162 13.7114 11.4338 13.8015 11.5943 13.9619Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.25052 13.9619C5.41098 13.8015 5.62857 13.7114 5.85546 13.7114C6.08235 13.7114 6.29994 13.8015 6.4604 13.9619L9.02733 16.5289C9.10906 16.6078 9.17424 16.7022 9.21908 16.8066C9.26393 16.911 9.28753 17.0233 9.28852 17.1369C9.28951 17.2505 9.26786 17.3632 9.22483 17.4683C9.18181 17.5735 9.11828 17.669 9.03794 17.7494C8.9576 17.8297 8.86207 17.8932 8.75691 17.9363C8.65175 17.9793 8.53908 18.0009 8.42547 17.9999C8.31186 17.999 8.19958 17.9754 8.09519 17.9305C7.9908 17.8857 7.89638 17.8205 7.81745 17.7388L5.25052 15.1718C5.09011 15.0114 5 14.7938 5 14.5669C5 14.34 5.09011 14.1224 5.25052 13.9619Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.42239 16.2781C8.19546 16.2781 7.97783 16.188 7.81736 16.0275C7.6569 15.867 7.56675 15.6494 7.56675 15.4225V8.57732C7.56675 8.35039 7.6569 8.13276 7.81736 7.97229C7.97783 7.81183 8.19546 7.72168 8.42239 7.72168C8.64932 7.72168 8.86696 7.81183 9.02742 7.97229C9.18789 8.13276 9.27804 8.35039 9.27804 8.57732V15.4225C9.27804 15.6494 9.18789 15.867 9.02742 16.0275C8.86696 16.188 8.64932 16.2781 8.42239 16.2781Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default DescendSortIcon;
