import React from 'react';
import { SvgIcon, useTheme } from '@material-ui/core';

const ClipboardListIcon = (props) => {
  const theme = useTheme();

  return (
    <SvgIcon {...props}>
      <path
        d="M9 0C7.04063 0 5.37188 1.14727 4.75781 2.75H3C1.34531 2.75 0 3.9832 0 5.5V19.25C0 20.7668 1.34531 22 3 22H15C16.6547 22 18 20.7668 18 19.25V5.5C18 3.9832 16.6547 2.75 15 2.75H13.2422C12.6281 1.14727 10.9594 0 9 0ZM9 2.75C9.39782 2.75 9.77936 2.89487 10.0607 3.15273C10.342 3.41059 10.5 3.76033 10.5 4.125C10.5 4.48967 10.342 4.83941 10.0607 5.09727C9.77936 5.35513 9.39782 5.5 9 5.5C8.60218 5.5 8.22064 5.35513 7.93934 5.09727C7.65804 4.83941 7.5 4.48967 7.5 4.125C7.5 3.76033 7.65804 3.41059 7.93934 3.15273C8.22064 2.89487 8.60218 2.75 9 2.75ZM3.375 11.6875C3.375 11.414 3.49353 11.1517 3.7045 10.9583C3.91548 10.7649 4.20163 10.6563 4.5 10.6562C4.79837 10.6563 5.08452 10.7649 5.2955 10.9583C5.50647 11.1517 5.625 11.414 5.625 11.6875C5.625 11.961 5.50647 12.2233 5.2955 12.4167C5.08452 12.6101 4.79837 12.7188 4.5 12.7188C4.20163 12.7188 3.91548 12.6101 3.7045 12.4167C3.49353 12.2233 3.375 11.961 3.375 11.6875ZM8.25 11H14.25C14.6625 11 15 11.3094 15 11.6875C15 12.0656 14.6625 12.375 14.25 12.375H8.25C7.8375 12.375 7.5 12.0656 7.5 11.6875C7.5 11.3094 7.8375 11 8.25 11ZM3.375 15.8125C3.375 15.539 3.49353 15.2767 3.7045 15.0833C3.91548 14.8899 4.20163 14.7812 4.5 14.7812C4.79837 14.7812 5.08452 14.8899 5.2955 15.0833C5.50647 15.2767 5.625 15.539 5.625 15.8125C5.625 16.086 5.50647 16.3483 5.2955 16.5417C5.08452 16.7351 4.79837 16.8438 4.5 16.8438C4.20163 16.8438 3.91548 16.7351 3.7045 16.5417C3.49353 16.3483 3.375 16.086 3.375 15.8125ZM7.5 15.8125C7.5 15.4344 7.8375 15.125 8.25 15.125H14.25C14.6625 15.125 15 15.4344 15 15.8125C15 16.1906 14.6625 16.5 14.25 16.5H8.25C7.8375 16.5 7.5 16.1906 7.5 15.8125Z"
        fill="#3F6C7B"
      />
    </SvgIcon>
  );
};

export default ClipboardListIcon;
